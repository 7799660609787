@import './vars.scss';
@import './colors.scss';
@import "~bootstrap/dist/css/bootstrap.css";

@font-face {
  font-family: 'UbuntuRegular';
  src: url('assets/Fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'UbuntuMedium';
  src: url('assets/Fonts/Ubuntu/Ubuntu-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'UbuntuBold';
  src: url('assets/Fonts/Ubuntu/Ubuntu-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'UbuntuItalic';
  src: url('assets/Fonts/Ubuntu/Ubuntu-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'UbuntuLightItalic';
  src: url('assets/Fonts/Ubuntu/Ubuntu-LightItalic.ttf') format('truetype');
}

body {
  font-family: 'UbuntuRegular', 'Open Sans', sans-serif;
  font-size: 16px;
  overflow: hidden;
  color: var(--textPrimary);
  // padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

.ng2-pdf-viewer-container {
  overflow-x: hidden;
}

.display1 {
  font-family: 'UbuntuBold';
  font-size: 1.56rem;
}

.display1Italic {
  font-family: 'UbuntuLightItalic';
  font-size: 1.56rem;
}

.display2 {
  font-family: 'UbuntuBold';
  font-size: 1.13rem;
}

.display2Italic {
  font-family: 'UbuntuLightItalic';
  font-size: 1.13rem;
}

.display3 {
  font-family: 'UbuntuBold';
  font-size: 1rem;
}

.display4 {
  font-family: 'UbuntuBold';
  font-size: .88rem;
}

.display5 {
  font-family: 'UbuntuBold';
  font-size: .75rem;
}

.label {
  font-family: 'UbuntuMedium';
  font-size: 0.88rem;
}

.labelItalic {
  font-family: 'UbuntuLightItalic';
  font-size: 0.88rem;
}

.text {
  font-family: 'UbuntuRegular';
  font-size: 0.75rem;
}

.textBold {
  font-family: 'UbuntuBold';
  font-size: 0.75rem;
}

.textItalic {
  font-family: 'UbuntuLightItalic';
  font-size: 0.75rem;
}

i {
  line-height: 0;
}

a {
  color: var(--textPrimary);
  cursor: pointer;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cp {
  cursor: pointer;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.jc_start {
  justify-content: flex-start;
}

.hidden_scroll {

  &::-webkit-scrollbar {
    display: none;
  }

}

.scroll {
  &::-webkit-scrollbar {
    width: 7px;
    background-color: var(--scroll);
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: grey;
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5)
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: grey;
  }
}

.scroll-h {
  &::-webkit-scrollbar {
    height: 7px;
    background-color: rgba(white, .2);
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: grey;
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5)
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: grey;
  }
}

.animation {
  animation-duration: 5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: preloadAnimation;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
}

@keyframes preloadAnimation {
  0% {
    background-position: -769px 0;
  }

  100% {
    background-position: 769px 0;
  }
}

.active>.page-link, .page-link.active {
  z-index: 3;
  color: #fff !important;
  background-color: #004581 !important;
  border-color: #004581 !important;
}

.page-link {
  color: #004581 !important;
  font-family: 'UbuntuRegular' !important;
  font-size: 0.75rem !important;
}

.pagination {
  justify-content: flex-end !important;
}

.md-drppicker .btn {
  padding: 2px 50px !important; 
  border: none !important;
  border-radius: 30px !important;
  color: white !important;
  text-transform: none !important;
  background: transparent linear-gradient(180deg, var(--gradient2) 0%, var(--gradient1) 100%) 0% 0% no-repeat padding-box !important;
 

  &:hover {
    background: var(--button)  !important; 
  }

  .label {
    font-size: .85rem  !important;
  }

  &._small {
    padding: 5px 25px !important;

    .label {
      font-size: .7rem !important;
    }    

  }

}

.md-drppicker td.active, .md-drppicker td.active:hover {
  background-color: #004581 !important;
}