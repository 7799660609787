:root {
  --gradient1: #004581;
  --gradient2: #008CC1;
  --secondary: #00B9FF;
  --disable: #979797;
  --button: #003564;
  --textPrimary: #35333B;
  --textSecondary: #DBDBDB;
  --bgPrimary: #E5E8E9;
  --bgSecondary: #FFFFFF;
  --success: #1AC915;
  --successSecondary: #00C167;
  --warning: #FFAA00;
  --error: #D4021F;
  --bg: #F3F4F8;
  --secondaryBg: #F3F3F3;
  --border: #707070;
  --border2: #BEBEBE;
  --scroll: #F1F1F1;
  --noProcessedBg: #D4021F0F;
  --noProcessed: #D4021F;
  --processedBg: #1AC9151C;
  --processed: #1AC915;
  --e14: #00B4C1;
  --e11: #007087;
  --incinerated: #D93E2C;
  --disableIcon: #CBCBCB;
  --placeholder: #C1C1C1;
}

.gradient1 {
  color: var(--gradient1);
}

.error {
  color: var(--error);
}

.gradient1Bg {
  background-color: var(--gradient1);
}

.errorBg {
  background-color: var(--error);
}
